import { FormikInput } from '@finn/auto-ui/components/B2BContactForm/Form';
import { Button } from '@finn/design-system';
import { JobAutoFormTypeCosmicData, UIBaseCosmicObject } from '@finn/ui-cosmic';
import { Box, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { FormikProps } from 'formik';
import React, { useState } from 'react';

import FormikCheckbox from '~/components/FormElements/Checkbox/FormikCheckbox';
import TextArea from '~/components/FormElements/TextArea';
import { getInputPlaceholder } from '~/utils/cosmic';
import { parseToHtml } from '~/utils/html';

import { FormValues } from './data';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(3),
      gridTemplateColumns: '1fr 1fr',
    },
  },
  fullWidth: {
    [theme.breakpoints.up('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  gdpr_container: {
    marginTop: -theme.spacing(1),
  },
}));

type JobAutoType = UIBaseCosmicObject<JobAutoFormTypeCosmicData>;

interface IProps {
  formik: FormikProps<FormValues>;
  loading: boolean;
  cosmicData: JobAutoType;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicData,
}) => {
  const classes = useStyles();
  const [showGdpr, setShowGdpr] = useState(false);
  const GDPR = cosmicData.metadata.input_fields.gdpr;

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdpr(!showGdpr);
  };

  return (
    <form>
      <Box className={classes.wrapper}>
        <Box className={classes.fullWidth}>
          <FormikInput
            name="company"
            label={getInputPlaceholder(cosmicData, 'company')}
          />
        </Box>
        <Box>
          <FormikInput
            name="firstName"
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </Box>
        <Box>
          <FormikInput
            name="lastName"
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </Box>
        <Box>
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </Box>
        <Box>
          <FormikInput
            name="phoneNumber"
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </Box>
        <Box>
          <FormikInput
            name="numberOfEmployees"
            label={getInputPlaceholder(cosmicData, 'number_of_employees')}
            type="number"
          />
        </Box>
        <Box>
          <FormikInput
            name="fleetSize"
            label={getInputPlaceholder(cosmicData, 'fleet_size')}
            type="number"
          />
        </Box>

        <Box className={classes.fullWidth}>
          <TextArea
            name="message"
            placeholder={getInputPlaceholder(cosmicData, 'message')}
          />
        </Box>
        <Box className={cn([classes.fullWidth, classes.gdpr_container])}>
          <FormikCheckbox name="gdpr" label={GDPR.title} />
          <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
            <Button variant="action" size="md" onClick={handleGDPRToggle}>
              {showGdpr ? GDPR.hide_details : GDPR.show_details}
            </Button>
            {showGdpr && (
              <>
                <br />
                <br />
                <p className="body-14-regular">{parseToHtml(GDPR.gdpr_note)}</p>
              </>
            )}
          </div>
        </Box>
        <Box className={classes.button}>
          <Button onClick={() => formik.handleSubmit()} loading={loading}>
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default Form;
